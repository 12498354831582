@import "../_vars"

$navHeight: 115px
$navHeightBig: 170px
$navHeightBigg: 225px
$breakWidth: 1010px
$breakWidthSmall: 735px

#mainContainer
  .maxbtn
    text-transform: lowercase !important

#recordeeNavBar
  position: fixed
  background-color: white
  width: 100%
  z-index: 1000
  overflow: hidden

  #recordee
    height: $navHeight
    @media all and (max-width: $breakWidth)
      height: $navHeightBig
    @media all and (max-width: $breakWidthSmall)
      height: $navHeightBigg
    padding-bottom: 0
    padding-top: 8px !important

    form
      display: inline-block
      padding: 0 20px

      label
        margin-right: 0
        display: inline-block

        select
          max-width: 200px

    .recorderStatus, .loginStatus
      line-height: 10px
      float: right
      padding: 10px
      border: 2px solid $tvg
      border-radius: 5px
      margin-left: 20px
      font-size: 20px
      margin-top: -30px
      margin-bottom: 0px
      padding-bottom: 14px

      &.recorderStatus
        svg
          color: #3dcc4a
          @keyframes blink
            50%
              opacity: 0.1

          margin-bottom: -2px
          animation: blink 1.2s step-start 0s infinite

//#mainContainer
  max-width: 1400px
  margin: 0 auto
  padding: 20px
//#UserLogin
  float: right
  border: 2px solid $tvg
  padding: 10px
  margin-bottom: 10px
#behindnav
  height: $navHeight

  @media all and (max-width: $breakWidth)
    height: $navHeightBig
  @media all and (max-width: $breakWidthSmall)
    height: $navHeightBigg

#JitsiMeetContainer
  text-align: center
  margin-top: 5px
  min-height: 300px
  //height: 50%
  background-color: #444
  @media all and (max-width: $breakWidth)
    margin-top: 5px

  iframe
    margin: 0 auto
    max-width: 100%

  #startingMessage
    position: absolute
    text-align: center
    line-height: 300px
    min-height: 300px
    //height: 50%
    width: 100%
    max-width: 100% !important
    color: white
    background-color: #444
    z-index: -1

#mainContainer
  .recordingsTable
    width: 100%

    tr
      td
        padding: 4px 16px
